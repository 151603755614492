import { useState } from "react";
import "./App.css";
import { setBackground } from "./Atoms/MoodChanges";
import "./Atoms/MoodChanges.css";

// Component
import Timer from "./Components/Timer/Timer";
import Chat from "./Components/Chat/Chat";
import Tasks from "./Components/Tasks/Tasks";
import Mood from "./Components/Mood/Mood";
import PopUp from "./Components/PopUp/PopUp";

function App() {
  // useState
  const [mood, setMood] = useState("");

  return (
    <div className={`App ${setBackground(mood)}`}>
      <div className="InnerContainer">
        <Timer mood={mood} />
        <Chat mood={mood} />
      </div>
      <div className="InnerContainer">
        <Tasks mood={mood} />
        <Mood mood={mood} setMood={setMood} />
      </div>
      <div className="ResponsiveText">
        Site's getting a glow-up for mobile & Ipad 🚧✨ Please use a bigger
        screen!
      </div>
      <PopUp mood={mood} />
    </div>
  );
}

export default App;
