import { createContext, useState } from "react";
import style from "./Tasks.module.css";
import { setContainer, setInput } from "../../Atoms/MoodChanges";

// Components
import TasksSide from "./TasksSide";
import NewTaskSide from "./NewTaskSide";

// Icons
import { Plus, X } from "@phosphor-icons/react";

// Context
export const tasksContext = createContext()

function Tasks({ mood }) {
    const [side, setSide] = useState("tasks");
    const [tasks, setTasks] = useState([]);
    const [doneTasks, setDoneTasks] = useState([]);
    const [filter, setFilter] = useState("all")

    // Context
    const contextValue = {
        side,
        setSide,
        tasks,
        setTasks,
        doneTasks,
        setDoneTasks,
        filter,
    }

    return (
        <tasksContext.Provider value={contextValue}>
            <div className={`${style.TasksContainer} ${setContainer(mood)}`}>
                <div className={style.Header}>
                    <div className={style.innerHeader}>
                        <div>Tasks</div>
                        {side === "tasks" && tasks.length > 0 &&
                            <div className={`${style.filterContainer} ${setInput(mood)}`}>
                                <select 
                                    value={filter} 
                                    onChange={(item) => setFilter(item.target.value)}
                                >
                                    <option value="all">All</option>
                                    <option value="high">High</option>
                                    <option value="medium">Medium</option>
                                    <option value="low">Low</option>
                                </select>
                            </div>
                        }
                    </div>
                    {side === "tasks" ?
                        <div 
                            className={style.AddButton} 
                            onClick={() => setSide("new-task")}
                        >
                            <Plus size={32} />
                        </div>
                    :
                        <div 
                            className={style.AddButton} 
                            onClick={() => setSide("tasks")}
                        >
                            <X size={32} />
                        </div>
                    }
                </div>
                {side === "tasks" && (
                    <>
                        <TasksSide mood={mood} />
                    </>
                )}
                {side === "new-task" && (
                    <>
                        <NewTaskSide mood={mood} />
                    </>
                )}
            </div>
        </tasksContext.Provider>
    );
}

export default Tasks;
