import { useContext, useState } from "react";
import style from "./Tasks.module.css";
import { setInput } from "../../Atoms/MoodChanges";

// Libraries
import { v4 as uuidv4 } from 'uuid';

// Icons
import { Plus } from "@phosphor-icons/react";

// Context
import { tasksContext } from "./Tasks";

function NewTaskSide({ mood }) {
    // useState
    const [newTask, setNewTask] = useState({
        id: "",
        content: "",
        priority: "high",
    });

    // Context
    const {
        setSide,
        setTasks,
    } = useContext(tasksContext)

    // Functions
    const clickHandler = () => {
        if (newTask) {
            setTasks((prevItems) => [...prevItems, {
                id: uuidv4().toString(),
                content: newTask.content,
                priority: newTask.priority,
            }]);
            setNewTask({
                id: "",
                content: "",
                priority: "high",
            });
            setSide("tasks");
        }
    }

    return (
        <div className={style.withoutTask}>
            <div className={`${style.addTask} ${setInput(mood)}`}>
                <input
                    placeholder="Task"
                    value={newTask.content}
                    onChange={(item) => setNewTask((prevItems) => ({...prevItems, content: item.target.value}))}
                />
                <div className={`${style.selectContainer} ${setInput(mood)}`}>
                    <select 
                        value={newTask.priority} 
                        onChange={(item) => setNewTask((prevItems) => ({...prevItems, priority: item.target.value}))}
                    >
                        <option value="high">High</option>
                        <option value="medium">Medium</option>
                        <option value="low">Low</option>
                    </select>
                </div>
                <div 
                    className={style.Button} 
                    onClick={clickHandler}
                >
                    <Plus size={24} />
                </div>
            </div>
        </div>
    );
}

export default NewTaskSide;
