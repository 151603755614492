import { createContext, useState } from "react";
import style from "./Mood.module.css"
import { setContainer } from "../../Atoms/MoodChanges";

// Components
import SelectSide from "./SelectSide";
import SelectAudioSide from "./SelectAudioSide";
import MoodSide from "./MoodSide";

// Icons
import { ArrowLeft, X } from "@phosphor-icons/react";

// Constants
import { steps } from "../../Constants";

// Context
export const moodsContext = createContext();

function Noise({ mood, setMood }) {
    // useState
    const [side, setSide] = useState("select-mood");
    const [audioType, setAudioType] = useState("");

    // Functions
    const clickHandler = (type, content) => {
        switch(type) {
            case "back":
                if (side === "select-audio-type") {
                    setMood(content);
                }
                const currentSide = steps.findIndex((step) => step === side);
                setSide(steps[currentSide - 1]);
                break
            case "close":
                setMood(content);
                setSide("select-mood");
                break
            case "select-mood":
                setMood(content);
                setSide("select-audio-type");
                break
            case "select-audio-type":
                setAudioType(content);
                setSide("mood");
                break
            default:
                break
        }
    };

    // Context
    const contextValue = {
        side,
        clickHandler,
        audioType,
    }

    return (
        <moodsContext.Provider value={contextValue}>
            <div className={`${style.MoodContainer} ${setContainer(mood)}`}>
                <div className={style.Header}>
                    <div>Moods</div>
                    <div className={style.HeaderButtons}>
                        {side !== "select-mood" && (
                            <ArrowLeft size={32} cursor="pointer" onClick={() => clickHandler("back", "")} />
                        )}
                        {side === "mood" && (
                            <X size={32} cursor="pointer" onClick={() => clickHandler("close", "")} />
                        )}
                    </div>
                </div>
                <div className={style.Body}>
                    {side === "select-mood" && (
                        <>
                            <SelectSide />
                        </>
                    )}
                    {side === "select-audio-type" && (
                        <>
                            <SelectAudioSide />
                        </>
                    )}
                    {side === "mood" && (
                        <>
                            <MoodSide mood={mood} />
                        </>
                    )}
                </div>
            </div>
        </moodsContext.Provider>
    );
}

export default Noise;