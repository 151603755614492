// Moods
export const steps = ["select-mood", "select-audio-type", "mood"]

export const AUDIO_LINKS = {
    NATURE: {
        SPRING: "https://www.dropbox.com/scl/fi/sd79dl3qs3mdhcb2366n3/Spring.mp3?rlkey=0wrj2w9a5ufq29tl0rgue859l&st=ms5hj44t&dl=0&raw=1",
        SUMMER: "https://www.dropbox.com/scl/fi/166l1596phbk01k0kwfwt/Summer.mp3?rlkey=5h2xpc3er9qp3b6ujll476d0q&st=ql7kicji&dl=0&raw=1",
        AUTUMN: "https://www.dropbox.com/scl/fi/5tf1vicpggqeqx2btmc23/Autumn.mp3?rlkey=60i34c17th898btbulrncaefc&st=babfkgdc&dl=0&raw=1",
        WINTER: "https://www.dropbox.com/scl/fi/nia9z85zwge66x7tq9y7s/Winter.mp3?rlkey=xk7ciiks86hfbtbsr1il3m00t&st=tl3tcr7o&dl=0&raw=1",
    }, 
    LOFI: [
        "https://www.dropbox.com/scl/fi/id933fjg623brohozxwni/For-a-Dream.mp3?rlkey=7vj72wqavc2gam0mnrupjlz5e&st=l4va3onf&raw=1",
        "https://www.dropbox.com/scl/fi/ynqvajmne2ux5euq7qyjp/Deep.mp3?rlkey=cdn0aw736bwij5i0y4cxt3lm8&st=npbwg6iq&raw=1",
    ]
}

// Timer
export const motivationalQuotes = {
    work: [
        `“The way to get started is to quit talking and begin doing“\n- Walt Disney`,
        `“The only limit to our realization of tomorrow is our doubts of today.”\n- Franklin D. Roosevelt`,
        `The journey of a thousand miles begins with a single step.”\n- Lao Tzu`,
        `“Believe you can and you´re halfway there.”\n- Theodore Roosevelt`,
        `“You don’t have to be great to start, but you have to start to be great.”\n- Zig Ziglar`
    ],
    break: [
        `“Well done is better than well said.”\n- Benjamin Franklin`,
        `“You’ve done it! Now take a moment to enjoy your achievement and realize the power of your efforts.”\n- Anonymous`,
        `“Celebrate your achievements, but always aim for higher.”\n- Anonymous`,
        `“Your hard work is paying off, and you’ve earned every bit of this success.”\n- Anonymous`,
        `“You did it! Now reflect on your hard work and enjoy the fruits of your labor.”\n- Anonymous`
    ],
    flow: [
        `“Don’t watch the clock; do what it does. Keep going.”\n- Sam Levenson`,
        `“The difference between ordinary and extraordinary is that little extra.”\n- Jimmy Johnson`,
        `“Extra work is not a punishment but a chance to rise above the ordinary and excel.”\n- Anonymous`,
        `“Hard work beats talent when talent doesn’t work hard.”\n- Tim Notke`,
        `“Effort only fully releases its reward after a person refuses to quit.”\n- Napoleon Hill`,
    ],
}

// Feedback
export const numbers = [1, 2, 3, 4, 5]