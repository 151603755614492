import { useState } from "react";
import style from "./PopUp.module.css";
import { setFeedback } from "../../Atoms/MoodChanges";

// Libraries
import emailjs from 'emailjs-com';

// Constants
import { numbers } from "../../Constants";
import { X } from "@phosphor-icons/react";

function PopUp({ mood }) {
    // useState
    const [popUp, setPopUp] = useState(false)
    const [rating, setRating] = useState(1)
    const [experience, setExperience] = useState("")
    const [email, setEmail] = useState("")

    const sendEmail = () => {    
        if (rating && experience) {
            emailjs.send(
                'service_je06rzh',
                'template_fkhpsvt',
                {
                    name: email,
                    rating: rating,
                    message: experience,
                },             
                'noMb6cxteBWVygOur'
            )
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

            setRating(1)
            setExperience("")
            setEmail("")
        }
    };

    const clickHandler = () => {
        setPopUp(!popUp)
    }

    return (
        <>
            {popUp &&
                <div className={style.PopUpPage}>
                    <div className={style.ClosePage} onClick={clickHandler}></div>
                    <div className={style.FeedbackContainer}>
                        <div className={style.FeedbackTitle}>Feedback</div>
                        <div className={style.RatingContainer}>
                            {numbers.map(item => (
                                <div 
                                    className={rating === item && style.SelectedRating}
                                    onClick={() => setRating(item)}
                                >
                                    {item}
                                </div>
                            ))}
                        </div>
                        <input value={experience} onChange={(item) => setExperience(item.target.value)} placeholder="Your experience / Report Bug" />
                        <input value={email} onChange={(item) => setEmail(item.target.value)} placeholder="E-mail (Optional)" />
                        <div>
                            <button onClick={sendEmail}>Send Feedback</button>
                        </div>
                        <div 
                            className={style.CloseFeedback}
                            onClick={clickHandler}
                        >
                            <X />
                        </div>
                    </div>
                </div>
            }
            <div 
                className={`${style.OpenFeedback} ${setFeedback(mood)}`}
                onClick={clickHandler}
            >
                Feedback
            </div>
        </>
    )
}

export default PopUp;