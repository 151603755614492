import style from "./Chat.module.css"

import { setContainer } from "../../Atoms/MoodChanges";

function Chat({ mood }) {
    return (
        <div className={`${style.ChatContainer} ${setContainer(mood)}`}>
            AI Coming soon!
        </div>
    );
}

export default Chat;