import { useContext } from "react";
import style from "./Mood.module.css"

// Icons
import { Leaf, MusicNotes, Shuffle } from "@phosphor-icons/react";

// Context
import { moodsContext } from "./Mood";

function SelectAudioSide() {
    // Context
    const {
        side,
        clickHandler,
    } = useContext(moodsContext)

    return (
        <>
            <div className={style.moodOption} onClick={() => clickHandler(side, "nature")}>
                <Leaf size={80} />
                <div>Nature</div>
            </div>
            <div className={style.moodOption} onClick={() => clickHandler(side, "lofi")}>
                <MusicNotes size={80} />
                <div>Lofi</div>
            </div>
            <div className={style.moodOption} onClick={() => clickHandler(side, "both")}>
                <Shuffle size={80} />
                <div>Both</div>
            </div>
        </>
    );
}

export default SelectAudioSide;