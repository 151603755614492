import { useContext } from "react";
import style from "./Timer.module.css";

// Icons
import { ArrowsCounterClockwise, Gear, Pause, Play } from "@phosphor-icons/react";

// Context
import { timerContext } from "./Timer";

function TimeSide({ mood }) {
    // Context
    const {
        time, 
        setTime, 
        workDuration, 
        breakDuration, 
        isActive,
        setIsActive,
        toggleStartPause, 
        isWorkSession, 
        setIsWorkSession, 
        resetTimer, 
        setSide
    } = useContext(timerContext)

    // Functions
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes < 10 ? "0" : ""}${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
    };

    const switchSession = () => {
        if (isWorkSession) {
            setIsActive(false)
            setTime(breakDuration)
            setIsWorkSession(false)
        } else {
            setIsActive(false)
            setTime(workDuration)
            setIsWorkSession(true)
        }
    }

    return (
        <>
            <div className={style.Time}>{formatTime(time)}</div>
            <div className={style.InnerContainer}>
                <div className={style.Controls}>
                    <div 
                        className={style.Button} 
                        onClick={toggleStartPause}
                    >
                        {isActive ? <Pause size={32} /> : <Play size={32} />}
                    </div>
                    <div 
                        className={style.Button} 
                        onClick={resetTimer}
                    >
                        <ArrowsCounterClockwise size={32} />
                    </div>
                    <div 
                        className={style.Button} 
                        onClick={() => setSide("setting-timer")}
                    >
                        <Gear size={32} />
                    </div>
                </div>
                <div 
                    className={`${style.Button} ${style.Switch}`} 
                    onClick={switchSession}
                >
                    {isWorkSession ? "Break" : "Work"}
                </div>
            </div>
        </>
    );
}

export default TimeSide;