import { useContext, useEffect, useState } from "react";
import style from "./Tasks.module.css";

// Libraries
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

// Icons
import { Check, Circle, Trash } from "@phosphor-icons/react";

// Context
import { tasksContext } from "./Tasks";

function TasksSide({ mood }) {
    // useState
    const [filteredTasks, setFilteredTasks] = useState([])

    // Context
    const { 
        tasks, 
        setTasks, 
        doneTasks, 
        setDoneTasks,
        filter,
    } = useContext(tasksContext);

    // Functions
    const renderStyle = (type, content = null) => {
        switch (type) {
            case "high":
                return { 
                    borderColor: "#EC0B43", 
                    color: "#EC0B43",
                    textDecorationLine: content && doneTasks.includes(content) && "line-through",
                }
            case "medium":
                return {
                    borderColor: "#F19A3E", 
                    color: "#F19A3E",
                    textDecorationLine: content && doneTasks.includes(content) && "line-through",
                }
            case "low":
                return {
                    borderColor: "#00A676", 
                    color: "#00A676",
                    textDecorationLine: content && doneTasks.includes(content) && "line-through",
                }
            default:
                break
        }
    }

    const onDragEnd = (result) => {
        const { destination, source } = result;

        if (!destination) return;
        if (destination.index === source.index) return;

        const newTasks = Array.from(tasks);
        const [movedTask] = newTasks.splice(source.index, 1);
        newTasks.splice(destination.index, 0, movedTask);
        setTasks(newTasks);
    };

    const clickHandler = (type, content) => {
        switch (type) {
            case "reopen":
                setDoneTasks((prevItems) => prevItems.filter((id) => id !== content))
                break;
            case "done":
                setDoneTasks((prevItems) => [...prevItems, content])
                break;
            case "delete":
                setTasks((prevItems) => prevItems.filter((item) => item.id !== content))
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (filter !== "all") {
            setFilteredTasks(tasks.filter(task => task.priority === filter))
        } else {
            setFilteredTasks(tasks)
        }
    }, [tasks, filter])

    return (
        <>
            {tasks.length > 0 ? (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="tasks">
                        {(provided) => (
                            <ul 
                                {...provided.droppableProps}
                                className={style.withTask} 
                                ref={provided.innerRef}
                            >
                                {filteredTasks.length > 0 ?
                                    filteredTasks.map((task, index) => (
                                        <Draggable 
                                            key={task.id} 
                                            draggableId={task.id} 
                                            index={index}
                                        >
                                            {(provided) => (
                                                <li
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className={`${style.task}`}
                                                    ref={provided.innerRef}
                                                    style={{
                                                        ...provided.draggableProps.style,
                                                        ...renderStyle(task.priority)
                                                    }}
                                                >
                                                    <div className={style.taskInfo}>
                                                        {doneTasks.includes(task.id) ? (
                                                            <Check 
                                                                size={24} 
                                                                cursor="pointer"
                                                                onClick={() => clickHandler("reopen", task.id)}
                                                            />
                                                        ) : (
                                                            <Circle
                                                                size={24}
                                                                cursor="pointer"
                                                                onClick={() => clickHandler("done", task.id)}
                                                            />
                                                        )}
                                                        <div className={style.taskName}>
                                                            <input
                                                                style={renderStyle(task.priority, task.id)}
                                                                value={task.content} 
                                                                onChange={(item) => 
                                                                    setTasks(tasks.map(element => element.id === task.id ? { ...element, content: item.target.value } : element))
                                                                } 
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={`${style.selectContainer}`}>
                                                        <select 
                                                            value={task.priority} 
                                                            onChange={(item) => 
                                                                setTasks(tasks.map(element => element.id === task.id ? { ...element, priority: item.target.value } : element))
                                                            }
                                                            style={renderStyle(task.priority)}
                                                        >
                                                            <option value="high">High</option>
                                                            <option value="medium">Medium</option>
                                                            <option value="low">Low</option>
                                                        </select>
                                                    </div>
                                                    <div className={style.taskButton}>
                                                        <Trash
                                                            size={24}
                                                            cursor="pointer"
                                                            onClick={() => clickHandler("delete", task.id)}
                                                        />
                                                    </div>
                                                </li>
                                            )}
                                        </Draggable>
                                    ))
                                :
                                    <div className={style.withoutTask}>There are no<span style={renderStyle(filter)}>{filter}</span> priority tasks</div>
                                }
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
            ) : (
                <div className={style.withoutTask}>
                    {`“Start where you are. Use what you have. Do what you can.”\n— Arthur Ashe`}
                </div>
            )}
        </>
    );
}

export default TasksSide;
