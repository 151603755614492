export const setBackground = mood => {
    return mood === "spring" ? "SpringBackground" :
    mood === "summer" ? "SummerBackground" :
    mood === "autumn" ? "AutumnBackground" :
    mood === "winter" ? "WinterBackground" : "DefaultBackground"
}

export const setContainer = mood => {
    return mood === "spring" ? "SpringContainer" :
    mood === "summer" ? "SummerContainer" :
    mood === "autumn" ? "AutumnContainer" :
    mood === "winter" ? "WinterContainer" : "DefaultContainer"
}

export const setInput = mood => {
    return mood === "spring" ? "SpringInput" :
    mood === "summer" ? "SummerInput" :
    mood === "autumn" ? "AutumnInput" :
    mood === "winter" ? "WinterInput" : "DefaultInput"
}

export const setFeedback = mood => {
    return mood === "spring" ? "SpringFeedback" :
    mood === "summer" ? "SummerFeedback" :
    mood === "autumn" ? "AutumnFeedback" :
    mood === "winter" ? "WinterFeedback" : "DefaultFeedback"
}