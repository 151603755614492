import { useContext, useEffect, useRef, useState } from "react";
import style from "./Timer.module.css";

// Icons
import { Books, Brain, Coffee } from "@phosphor-icons/react";

// Context & Constants
import { timerContext } from "./Timer";
import { motivationalQuotes } from "../../Constants";

function AlarmSide({ mood }) {
    // useState & useRef
    const [isFlow, setIsFlow] = useState(false)
    const audioRef = useRef(null);

    // Context
    const {
        setTime, 
        workDuration, 
        breakDuration, 
        isWorkSession, 
        isAlarm,
        setIsAlarm,
        setIsWorkSession, 
    } = useContext(timerContext)

    // Functions
    useEffect(() => {
        const audio = audioRef.current;

        const playAudio = async (src) => {
            if (audio) {
                audio.src = src;
                try {
                    await audio.play();
                } catch (error) {
                    console.error(error);
                }
            }
        };

        const fetchAudioSrc = async (type) => {
            const links = {
                work: "https://dl.dropboxusercontent.com/scl/fi/800fr3ga1dm7zjf51lu1d/Twinkle.mp3?rlkey=hg0pk2bibnteajpmw5xeby4n2",
                break: "https://dl.dropboxusercontent.com/scl/fi/djgiqq23ynl17gx1zmvaw/Ding.mp3?rlkey=tctfwhpq0zszpehtsvjuy9ld1",
            };
            return links[type];
        };

        const type = isWorkSession ? "work" : "break";
        fetchAudioSrc(type).then(playAudio);

        return () => {
            if (audio) {
                audio.pause();
                audio.src = ""; 
            }
        };
    }, [isWorkSession]);

    const clickHandler = (type) => {
        switch (type) {
            case "break":
                setIsWorkSession(!isWorkSession);
                setTime(breakDuration);
                setIsAlarm(!isAlarm);
                break;
            case "work":
                setIsWorkSession(!isWorkSession);
                setTime(workDuration);
                setIsAlarm(!isAlarm);
                break;
            case "flow":
                setIsFlow(true);
                break;
            default:
                break;
        }
    }

    const getQuote = (quotes) => {
        return Math.floor(Math.random() * quotes)
    }

    if (isWorkSession) {
        if (!isFlow) {
            return (
                <div className={style.AlarmContainer}>
                    <audio ref={audioRef} />
                    <div className={style.Motivation}>{motivationalQuotes.break[getQuote(motivationalQuotes.break.length)]}</div>
                    <div className={style.InnerContainer}>
                        <div className={style.AlarmButton} onClick={() => clickHandler("break")}>
                            <Coffee size={80} />
                            <div>Break</div>
                        </div>
                        <div className={style.AlarmButton} onClick={() => clickHandler("flow")}>
                            <Brain size={80} />
                            <div>Continue</div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className={style.AlarmContainer}>
                    <div className={style.Motivation}>{motivationalQuotes.flow[getQuote(motivationalQuotes.flow.length)]}</div>
                    <div className={style.InnerContainer}>
                        <div className={style.AlarmButton} onClick={() => clickHandler("break")}>
                            <Coffee size={80} />
                            <div>Break</div>
                        </div>
                    </div>
                </div>
            );
        }
    } else {
        return (
            <div className={style.AlarmContainer}>
                <audio ref={audioRef} />
                <div className={style.Motivation}>{motivationalQuotes.work[getQuote(motivationalQuotes.work.length)]}</div>
                <div className={style.InnerContainer}>
                    <div className={style.AlarmButton} onClick={() => clickHandler("work")}>
                        <Books size={80} />
                        <div>Start</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AlarmSide;