import { useContext } from "react";
import style from "./Mood.module.css"

// Icons
import { FlowerTulip, Snowflake, Sun, Tree } from "@phosphor-icons/react";

// Context
import { moodsContext } from "./Mood";

function SelectSide() {
    // Context
    const {
        side,
        clickHandler,
    } = useContext(moodsContext)

    return (
        <>
            <div className={style.moodOption} onClick={() => clickHandler(side, "spring")}>
                <FlowerTulip size={80} />
                <div>Spring</div>
            </div>
            <div className={style.moodOption} onClick={() => clickHandler(side, "summer")}>
                <Sun size={80} />
                <div>Summer</div>
            </div>
            <div className={style.moodOption} onClick={() => clickHandler(side, "autumn")}>
                <Tree size={80} />
                <div>Autumn</div>
            </div>
            <div className={style.moodOption} onClick={() => clickHandler(side, "winter")}>
                <Snowflake size={80} />
                <div>Winter</div>
            </div>
        </>
    );
}

export default SelectSide;