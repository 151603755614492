import { createContext, useEffect, useState } from "react";
import style from "./Timer.module.css"
import { setContainer } from "../../Atoms/MoodChanges";

// Components
import TimeSide from "./TimeSide";
import SettingSide from "./SettingSide";
import AlarmSide from "./AlarmSide";

// Context
export const timerContext = createContext();

function Timer({ mood }) {
    // useState
    const [side, setSide] = useState("timer")
    const [workDuration, setWorkDuration] = useState(25 * 60)
    const [breakDuration, setBreakDuration] = useState(5 * 60)
    const [time, setTime] = useState(workDuration);
    const [isActive, setIsActive] = useState(false);
    const [isWorkSession, setIsWorkSession] = useState(true);
    const [isAlarm, setIsAlarm] = useState(false)

    // Functions
    useEffect(() => {
        let interval = null;

        if (!isAlarm) {
            if (isActive && time > 0) {
                interval = setInterval(() => {
                    setTime((time) => time - 1);
                }, 1000);
            } else if (time === 0) {
                setIsAlarm(true)
            }
        }

        return () => clearInterval(interval);
    }, [time, isWorkSession, breakDuration, workDuration, isActive, isAlarm]);

    useEffect(() => {
        if (side === "timer") {
            setTime(isWorkSession ? workDuration : breakDuration);
        }
    }, [side, isWorkSession, breakDuration, workDuration])

    useEffect(() => {
        if (!isAlarm) {
            setSide("timer")
        } else {
            setSide("alarm")
        }
    }, [isAlarm])

    const toggleStartPause = () => {
        setIsActive(!isActive);
    };
    
    const resetTimer = () => {
        setIsActive(false);
        setTime(isWorkSession ? workDuration : breakDuration);
    };

    // Context
    const contextValue = {
        workDuration,
        setWorkDuration,
        breakDuration,
        setBreakDuration,
        time,
        setTime,
        isActive,
        setIsActive,
        isWorkSession,
        setIsWorkSession,
        isAlarm,
        setIsAlarm,
        side,
        setSide,
        toggleStartPause,
        resetTimer,
    }

    return (
        <timerContext.Provider value={contextValue}>
            <div className={`${style.TimerContainer} ${setContainer(mood)}`}>
                {side === "timer" &&
                    <TimeSide mood={mood} />
                }
                {side === "setting-timer" &&
                    <SettingSide mood={mood} />
                }
                {side === "alarm" &&
                    <AlarmSide mood={mood} />
                }
            </div>
        </timerContext.Provider>
    );
}

export default Timer;