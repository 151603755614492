import { useCallback, useContext, useEffect, useRef, useState } from "react";
import style from "./Mood.module.css"
import { setInput } from "../../Atoms/MoodChanges";

// Icons
import { FlowerTulip, MusicNotes, Snowflake, Sun, Tree } from "@phosphor-icons/react";

// Constants
import { AUDIO_LINKS } from "../../Constants";

// Context
import { moodsContext } from "./Mood";

function MoodSide({ mood }) {
    // useState & useRef
    const [songs, setSongs] = useState([]);
    const [currentSong, setCurrentSong] = useState(0);
    const audioRefNature = useRef();
    const audioRefLofi = useRef();

    // Context
    const {
        side,
        audioType,
    } = useContext(moodsContext)

    // Functions
    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    const playAudio = useCallback(async (audio) => {
        if (songs[currentSong]) {
            if (audio.src !== songs[currentSong]) {
                audio.src = songs[currentSong];
                audio.oncanplaythrough = async () => {
                    try {
                        await audio.play();
                    } catch (error) {
                        console.error("Failed to play audio:", error);
                    }
                };
            }
        }
    }, [songs, currentSong]);

    const playNature = async (audio) => {
        try {
            await audio.play();
        } catch (error) {
            console.error("Failed to play audio:", error);
        }
    }
    
    const handleEnded = useCallback(() => {
        const newSong = (currentSong + 1) % songs.length;
        setCurrentSong(newSong);
    }, [currentSong, songs.length]);

    const rendermood = () => {
        switch (mood) {
            case "spring":
                return <FlowerTulip size={128} />;
            case "summer":
                return <Sun size={128} />;
            case "autumn":
                return <Tree size={128} />;
            case "winter":
                return <Snowflake size={128} />;
            default:
                return null;
        }
    };
    
    useEffect(() => {
        if (side === "mood" && songs.length > 0) {
            if (audioRefLofi.current) {
                playAudio(audioRefLofi.current);
            }
            if (audioRefNature.current) {
                playNature(audioRefNature.current);
            }
        }
    }, [side, playAudio, songs]);
    
    useEffect(() => {
        if (side === "mood") {
            const shuffledSongs = shuffleArray([...AUDIO_LINKS.LOFI]);
            setSongs(shuffledSongs);
            setCurrentSong(0);
        }
    }, [side]);

    return (
        <>
            <div className={style.innerContainer}>
                {audioType === "nature" && (
                    <>
                        <div>{rendermood()}</div>
                        <div className={style.inputContainer}>
                            <audio
                                src={AUDIO_LINKS.NATURE[mood.toUpperCase()]}
                                ref={audioRefNature}
                                loop
                            />
                            <input
                                className={setInput(mood)}
                                onChange={(element) => (audioRefNature.current.volume = element.target.value)}
                                type="range"
                                min="0"
                                max="1"
                                step="0.01"
                            />
                        </div>
                    </>
                )}
                {audioType === "lofi" && (
                    <>
                        <div>
                            <MusicNotes size={128} />
                        </div>
                        <div className={style.inputContainer}>
                            <audio
                                ref={audioRefLofi}
                                onEnded={handleEnded}
                                src={songs[currentSong]}
                                autoPlay
                            />
                            <input
                                className={setInput(mood)}
                                onChange={(element) => (audioRefLofi.current.volume = element.target.value)}
                                type="range"
                                min="0"
                                max="1"
                                step="0.01"
                            />
                        </div>
                    </>
                )}
                {audioType === "both" && (
                    <>
                        <div className={style.inputContainer}>
                            <div className={style.AudioContainer}>
                                <audio
                                    ref={audioRefLofi}
                                    onEnded={handleEnded}
                                    src={songs[currentSong]}
                                    autoPlay
                                />
                                <MusicNotes size={128} />
                                <input
                                    className={setInput(mood)}
                                    onChange={(element) => (audioRefLofi.current.volume = element.target.value)}
                                    type="range"
                                    min="0"
                                    max="1"
                                    step="0.01"
                                />
                            </div>
                            <div className={style.AudioContainer}>
                                <audio
                                    src={AUDIO_LINKS.NATURE[mood.toUpperCase()]}
                                    ref={audioRefNature}
                                    loop
                                />
                                {rendermood()}
                                <input
                                    className={setInput(mood)}
                                    onChange={(element) => (audioRefNature.current.volume = element.target.value)}
                                    type="range"
                                    min="0"
                                    max="1"
                                    step="0.01"
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

export default MoodSide;