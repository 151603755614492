import { useContext, useState } from "react";
import style from "./Timer.module.css"
import { setInput } from "../../Atoms/MoodChanges";

// Context
import { timerContext } from "./Timer";
import { FloppyDisk, X } from "@phosphor-icons/react";

function SettingSide({ mood }) {
    // useStates
    const [tempWorkDuration, setTempWorkDuration] = useState()
    const [tempBreakDuration, setTempBreakDuration] = useState()
    const [error, setError] = useState({
        workInput: false,
        breakInput: false,
    })

    // Context
    const {
        setWorkDuration,
        setBreakDuration,
        setIsActive,
        setSide,
    } = useContext(timerContext)

    // Functions
    const clickHandler = (type) => {
        if (type === "save") {
            setWorkDuration(tempWorkDuration * 60)
            setBreakDuration(tempBreakDuration * 60)
        }

        setIsActive(false)
        setSide("timer")
    }

    return (
        <>
            <div className={style.InputContainer}>
                <div className={`${style.timeInput} ${setInput(mood)}`} style={error.workInput ? {borderColor: "red"} : null}>
                    <input 
                        className={setInput(mood)} 
                        placeholder="Work Duration" 
                        type="number" value={tempWorkDuration} 
                        onChange={(element) => {
                            setError((prevItem) => ({
                                ...prevItem,
                                workInput: false
                            }))
                            setTempWorkDuration(element.target.value)
                        }} 
                    />
                </div>
                <div className={`${style.timeInput} ${setInput(mood)}`} style={error.breakInput ? {borderColor: "red"} : null}>
                    <input 
                        className={setInput(mood)} 
                        placeholder="Break Duration" 
                        type="number" 
                        value={tempBreakDuration} 
                        onChange={(element) => {
                            setError((prevItem) => ({
                                ...prevItem,
                                breakInput: false
                            }))
                            setTempBreakDuration(element.target.value)
                        }} 
                    />
                </div>
                <div 
                    className={`${style.Button}`} 
                    onClick={() => {
                        if (tempWorkDuration && tempBreakDuration) {
                            clickHandler("save")
                        } else {
                            if (!tempWorkDuration) {
                                setError((prevItem) => ({
                                    ...prevItem,
                                    workInput: true
                                }))
                            } if (!tempBreakDuration) {
                                setError((prevItem) => ({
                                    ...prevItem,
                                    breakInput: true
                                }))
                            }
                        }
                    }}
                >
                    <FloppyDisk size={32} />
                </div>
            </div>
            <div 
                className={`${style.Button} ${style.CloseButton}`} 
                onClick={() => clickHandler("back")}
            >
                <X size={32} />
            </div>
        </>
    );
}

export default SettingSide;